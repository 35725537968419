.rh5i1zy{font-weight:700;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;width:clamp(400px, 70%, 1024px);}
.t92s1lk{font-size:18px;font-weight:800;}
.i16ycmb5{color:#C3CBD9;}
.s7ea3n9{color:#D94143;}
.l1klvf2u{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;}
.iff6q9o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.b10uwz4x{position:absolute;top:-25%;left:-25%;color:white;background-color:#D94143;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;width:1.5rem;height:1.5rem;font-size:12px;}
.ey4nzie{background:#D9414319;color:#D94143;padding:1rem;border-radius:1rem;font-weight:300;}
.d1b7ebek{font-weight:600;}
.c5rl739{position:relative;padding-left:0.5rem;padding-right:0.5rem;border:none;box-shadow:0px 0px 14.1px -2px rgba(195, 203, 217, 0.4);}
